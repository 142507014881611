var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"resources-table"},[_c('div',[_c('table-default',{attrs:{"id":"table-resources","rows":_vm.list,"fields":_vm.fields,"stickyHeader":false,"striped":true,"searchFilter":false,"filter":_vm.filter},on:{"updatePageNumber":_vm.updatePageNumber,"updatepageSize":_vm.updatepageSize},scopedSlots:_vm._u([{key:"head(name)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(data.label))+" ")]),_c('div',{staticClass:"d-flex flex-column sortable-icons align-items-end"},[_c('ArrowDown',{staticClass:"rotate",style:(_vm.filter.sort === 'asc' && _vm.filter.fieldSort === data.field.key
                  ? 'fill: #998F8A'
                  : 'opacity: 0.5'),on:{"click":function($event){return _vm.updateFieldAndSort(data.field.key, 'asc')}}}),_c('ArrowDown',{style:(_vm.filter.sort === 'desc' && _vm.filter.fieldSort === data.field.key
                  ? 'fill: #998F8A'
                  : 'opacity: 0.5'),on:{"click":function($event){return _vm.updateFieldAndSort(data.field.key, 'desc')}}})],1)])]}},{key:"head(code)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t(data.label))+" ")]),_c('div',{staticClass:"d-flex flex-column sortable-icons align-items-end"},[_c('ArrowDown',{staticClass:"rotate",style:(_vm.filter.sort === 'asc' && _vm.filter.fieldSort === data.field.key
                  ? 'fill: #998F8A'
                  : 'opacity: 0.5'),on:{"click":function($event){return _vm.updateFieldAndSort(data.field.key, 'asc')}}}),_c('ArrowDown',{style:(_vm.filter.sort === 'desc' && _vm.filter.fieldSort === data.field.key
                  ? 'fill: #998F8A'
                  : 'opacity: 0.5'),on:{"click":function($event){return _vm.updateFieldAndSort(data.field.key, 'desc')}}})],1)])]}},{key:"head(user)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t(data.label))+" ")]),_c('div',{staticClass:"d-flex flex-column sortable-icons align-items-end"},[_c('ArrowDown',{staticClass:"rotate",style:(_vm.filter.sort === 'asc' && _vm.filter.fieldSort === data.field.key
                  ? 'fill: #998F8A'
                  : 'opacity: 0.5'),on:{"click":function($event){return _vm.updateFieldAndSort(data.field.key, 'asc')}}}),_c('ArrowDown',{style:(_vm.filter.sort === 'desc' && _vm.filter.fieldSort === data.field.key
                  ? 'fill: #998F8A'
                  : 'opacity: 0.5'),on:{"click":function($event){return _vm.updateFieldAndSort(data.field.key, 'desc')}}})],1)])]}},{key:"head(function)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t(data.label))+" ")]),_c('div',{staticClass:"d-flex flex-column sortable-icons align-items-end"},[_c('ArrowDown',{staticClass:"rotate",style:(_vm.filter.sort === 'asc' && _vm.filter.fieldSort === data.field.key
                  ? 'fill: #998F8A'
                  : 'opacity: 0.5'),on:{"click":function($event){return _vm.updateFieldAndSort(data.field.key, 'asc')}}}),_c('ArrowDown',{style:(_vm.filter.sort === 'desc' && _vm.filter.fieldSort === data.field.key
                  ? 'fill: #998F8A'
                  : 'opacity: 0.5'),on:{"click":function($event){return _vm.updateFieldAndSort(data.field.key, 'desc')}}})],1)])]}},{key:"head(email)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t(data.label))+" ")]),_c('div',{staticClass:"d-flex flex-column sortable-icons align-items-end"},[_c('ArrowDown',{staticClass:"rotate",style:(_vm.filter.sort === 'asc' && _vm.filter.fieldSort === data.field.key
                  ? 'fill: #998F8A'
                  : 'opacity: 0.5'),on:{"click":function($event){return _vm.updateFieldAndSort(data.field.key, 'asc')}}}),_c('ArrowDown',{style:(_vm.filter.sort === 'desc' && _vm.filter.fieldSort === data.field.key
                  ? 'fill: #998F8A'
                  : 'opacity: 0.5'),on:{"click":function($event){return _vm.updateFieldAndSort(data.field.key, 'desc')}}})],1)])]}},{key:"head(phone)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t(data.label))+" ")]),_c('div',{staticClass:"d-flex flex-column sortable-icons align-items-end"},[_c('ArrowDown',{staticClass:"rotate",style:(_vm.filter.sort === 'asc' && _vm.filter.fieldSort === data.field.key
                  ? 'fill: #998F8A'
                  : 'opacity: 0.5'),on:{"click":function($event){return _vm.updateFieldAndSort(data.field.key, 'asc')}}}),_c('ArrowDown',{style:(_vm.filter.sort === 'desc' && _vm.filter.fieldSort === data.field.key
                  ? 'fill: #998F8A'
                  : 'opacity: 0.5'),on:{"click":function($event){return _vm.updateFieldAndSort(data.field.key, 'desc')}}})],1)])]}},{key:"cell(action)",fn:function(data){return [_c('div',{staticClass:"action-block"},[_c('OptionIcon',{staticClass:"action-svg",attrs:{"id":'action-' + data.index,"tabindex":"0"},on:{"click":function($event){return _vm.getItem(data.item)}}}),_c('Popover',{attrs:{"items":_vm.actionList,"target":'action-' + data.index,"placement":"bottomright","custom-position":"action-button","triggers":"focus"}})],1)]}},{key:"cell(name)",fn:function(data){return [_c('div',{staticClass:"name-block"},[(data.item.photo)?_c('img',{attrs:{"src":data.item.photo,"alt":"User photo"}}):_c('AvatarPlaceholder',{attrs:{"text":_vm.picturePlaceholder(data.item.firstName, data.item.lastName)}}),_vm._v(" "+_vm._s(((data.item.firstName) + " " + (data.item.lastName)))+" ")],1)]}},{key:"cell(function)",fn:function(data){return [(data.item.functions.length)?_c('div',{staticClass:"roles-block"},[_c('div',{staticClass:"role-tag"},[_vm._v(" "+_vm._s(_vm.$t(data.item.functions[0].name))+" ")]),(data.item.functions[1])?_c('div',{staticClass:"role-tag"},[_vm._v(" "+_vm._s(_vm.$t(data.item.functions[1].name))+" ")]):_vm._e(),(data.item.functions.length > 2)?_c('div',{staticClass:"role-tag"},[_vm._v(" + "+_vm._s(data.item.functions.length - 2)+" ")]):_vm._e()]):_vm._e()]}},{key:"cell(phone)",fn:function(data){return [_c('div',[_vm._v(" "+_vm._s(_vm.formatPhoneNumber(data.item.phone))+" ")])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }