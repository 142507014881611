<template>
  <div>
    <modal-add-edit
      :device="device"
      :modalTitle="modalTitle"
      :personSelected="personSelected"
      :userInfos="userInfos"
      :errorCode="errorCode"
      :currentGuid="currentGuid"
      :departmentsList="departmentsList"
      @updateList="updateListFunc()"
      @clearUserInfo="userInfos = {}"
      @clearErroCode="clearCodeError()"
      @nextStep="nextStep"
    />
    <modal-reset-password
      :device="device"
      :isAdd="isAddPeople"
      modalTitle="PeoplePage.ResetPassword"
      :personSelected="personSelected"
      :userInfos="userInfos"
      :currentGuid="currentGuid"
      @clearUserInfo="clearUserInfoAndPeopleSelected"
      @updateList="getPeople"
      @previousStep="previousStep"
    />
    <div id="people-page">
      <div
        v-if="list.length === 0 && !noItemsFound"
        class="h-100"
      >
        <div class="no-items h-100">
          <EmptyBoxIcon class="empty-box" />
          <p class="title">{{ $t('PeoplePage.NoPeopleTitle') }}</p>
          <p class="subtitle">{{ $t('PeoplePage.NoPeopleSubtitle') }}</p>
          <b-button
            class="btn-new-person"
            @click="changeModalName('new')"
          >
            <AddIcon class="add-icon" />
            {{ $t('PeoplePage.NewPerson') }}
          </b-button>
        </div>
      </div>
      <div v-else>
        <div class="header-block">
          <div class="title">
            {{ $t('PagesViews.People') }}
          </div>
          <div class="search-input col-12">
            <b-col
              cols="12"
              sm="9"
              md="8"
              lg="10"
              xl="10"
              class="div-custom-input input__people"
            >
              <div class="icon-block">
                <SearchIcon class="search-icon" />
              </div>
              <b-form-tags
                class="input-search-people"
                ref="formTagPeople"
                v-model="filter.textFilter"
                duplicate-tag-text=""
                :placeholder="filter.textFilter.length === 0 ? $t('SearchPlaceholderDefault') : ''"
                @input="getPeople()"
                remove-on-delete
                add-on-change
              />
            </b-col>
            <b-col
              cols="12"
              sm="3"
              md="4"
              lg="2"
              xl="2"
              class="pr-1 pr-sm-0 add-person"
            >
              <b-button
                class="btn-new-person"
                @click="changeModalName('new')"
              >
                <AddIcon class="add-icon" />
                {{ $t('PeoplePage.AddPerson') }}
              </b-button>
            </b-col>
          </div>
        </div>
        <div class="body-block">
          <people-table
            :list="list"
            :filter="filter"
            :prefix="prefix"
            @reset="(id) => resetPassword(id)"
            @edit="(id) => changeModalName('edit', id)"
            @deletedPerson="getPeople"
            @updatePageNumber="updatePageNumber"
            @updatepageSize="updatepageSize"
            @updateFieldAndSort="updateFieldAndSort"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapMutations, mapState } from 'vuex';
  import { BFormTags, BButton, BCol } from 'bootstrap-vue';
  import EmptyBoxIcon from '@core/assets/icons/empty-box-icon.svg';
  import AddIcon from '@core/assets/icons/add-icon.svg';
  import PeopleTable from './components/people-table.vue';
  import ModalAddEdit from './components/ModalAddEdit.vue';
  import ModalResetPassword from './components/ModalResetPassword.vue';

  export default {
    components: {
      BFormTags,
      BButton,
      BCol,
      PeopleTable,
      ModalAddEdit,
      ModalResetPassword,
      AddIcon: () => import('@core/assets/icons/add-icon.svg'),
      CloseIcon: () => import('@core/assets/icons/close-icon.svg'),
      SearchIcon: () => import('@core/assets/icons/search-icon.svg'),
      AddIcon,
      EmptyBoxIcon
    },
    data() {
      return {
        filter: {
          pageNumber: 1,
          pageSize: 10,
          totalItems: 0,
          sort: '',
          fieldSort: '',
          textFilter: []
        },
        currentGuid: null,
        userInfos: {},
        errorCode: [],
        departmentsList: [],
        sidebarVisible: false,
        isAddPeople: false,
        personSelected: {},
        modalTitle: '',
        prefix: localStorage.getItem('prefix'),
        noItemsFound: false,
        device:
          window.innerWidth > 768
            ? 'desktop'
            : window.innerWidth > 480 && window.innerWidth <= 768
            ? 'tablet'
            : 'mobile',
        list: []
      };
    },
    created() {
      window.addEventListener('resize', this.handleResize);
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize);
      if (this.editPerson) this.clearEditPerson();
    },
    async mounted() {
      this.UPDATE_FLAG_SHOW_OVERLAY(true);
      await this.$http2.get(`/api/myconfig/user/department/context`).then((response) => {
        this.departmentsList = response.data.data
          .map((item) => ({
            value: item.id,
            text: item.name
          }))
          .sort((a, b) => a.text.localeCompare(b.text));
      });
      this.getPeople();
      if (this.editPerson) {
        this.searchEditPerson(this.editPerson);
      }
    },
    methods: {
      ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY']),
      ...mapMutations('people', [
        'UPDATE_MODAL_ADD_EDIT_TOGGLE',
        'UPDATE_MODAL_RESET_TOGGLE',
        'UPDATE_EDIT_PERSON'
      ]),
      handleResize() {
        this.device =
          window.innerWidth > 768
            ? 'desktop'
            : window.innerWidth > 480 && window.innerWidth <= 768
            ? 'tablet'
            : 'mobile';
      },
      clearCodeError() {
        this.errorCode = [];
        if (this.editPerson) this.clearEditPerson();
      },
      updateListFunc() {
        if (this.editPerson) this.clearEditPerson();
        this.getPeople();
      },
      clearEditPerson() {
        this.UPDATE_EDIT_PERSON(null);
        this.filter.textFilter = [];
        this.getPeople();
      },
      clearUserInfoAndPeopleSelected() {
        this.userInfos = {};
        this.personSelected = {};
      },
      activeCarousel() {
        const vm = this;
        $(function () {
          document.getElementsByClassName('input-search-people')[0].setAttribute('tabIndex', '-1');

          const slider = document.querySelector('.b-form-tags-list');
          let mouseDown = false;
          let startX, scrollLeft;

          let startDragging = function (e) {
            mouseDown = true;
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
          };
          let stopDragging = function () {
            mouseDown = false;
          };

          slider.addEventListener('mousemove', (e) => {
            e.preventDefault();
            if (!mouseDown) {
              return;
            }
            const x = e.pageX - slider.offsetLeft;
            const scroll = x - startX;
            slider.scrollLeft = scrollLeft - scroll;
          });

          slider.addEventListener('mousedown', startDragging, false);
          slider.addEventListener('mouseup', stopDragging, false);
          slider.addEventListener('mouseleave', stopDragging, false);

          $('.b-form-tags-input').blur((event) => {
            let input = vm.$refs.formTagPeople;
            input.addTag(event.target.value);
          });
        });
      },
      updatepageSize(value) {
        this.filter.pageSize = value;
      },
      updatePageNumber(value) {
        this.filter.pageNumber = value;
      },
      updateFieldAndSort(e, text) {
        this.filter.sort = this.filter.sort = text;
        this.filter.fieldSort = e;
      },
      resetPassword(guid) {
        this.currentGuid = guid;
        this.isAddPeople = false;
        this.personSelected = this.list.find((e) => e.guid === guid);
        this.UPDATE_MODAL_RESET_TOGGLE(true);
      },
      changeModalName(name, guid) {
        if (name === 'new') {
          this.modalTitle = 'PeoplePage.GeneralInformation';
          this.personSelected = null;
        }
        if (name === 'edit') {
          this.currentGuid = guid;
          this.modalTitle = 'PeoplePage.EditPerson';
          this.personSelected = this.list.find((e) => e.guid === guid);
        }
        this.UPDATE_MODAL_ADD_EDIT_TOGGLE(true);
      },
      previousStep(code) {
        this.errorCode = [...this.errorCode, code];
        if (!code || code === 64 || code === 72) {
          this.UPDATE_MODAL_ADD_EDIT_TOGGLE(true);
          this.UPDATE_MODAL_RESET_TOGGLE(false);
        }
      },
      nextStep(item) {
        this.userInfos = item;
        this.isAddPeople = true;
        this.errorCode = [];
        this.UPDATE_MODAL_RESET_TOGGLE(true);
        this.UPDATE_MODAL_ADD_EDIT_TOGGLE(false);
      },
      async getPeople() {
        this.UPDATE_FLAG_SHOW_OVERLAY(true);
        this.userInfos = {};

        const textFilterQuery = this.filter.textFilter;
        await this.$http2
          .get(
            `/api/myconfig/user?PageSize=${this.filter.pageSize}&PageNumber=${this.filter.pageNumber}&PageOrder=${this.filter.sort}&PageOrderField=${this.filter.fieldSort}&TextFilter=${textFilterQuery}`
          )
          .then((response) => {
            this.noItemsFound = !!textFilterQuery.length;
            this.list = response.data.data.users;
            this.filter.totalItems = response.data.data.totalItems;
            this.activeCarousel();
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
          });
      },
      async searchEditPerson(person) {
        this.filter.textFilter.push(person.user);
        await this.getPeople();
        const personFiltred = this.list.find((e) => e.guid === person.guid);
        if (personFiltred) {
          this.changeModalName('edit', personFiltred.guid);
        } else {
          this.filter.textFilter = [];
          await this.getPeople();
        }
      }
    },
    computed: {
      ...mapState('people', {
        editPerson: 'editPerson'
      }),
      displayImportantFirst() {
        return this.customFields.sort((a, b) => {
          if (a.important && !b.important) {
            return -1;
          }
          if (!a.important && b.important) {
            return 1;
          }
          return 0;
        });
      }
    },
    watch: {
      'filter.pageNumber'() {
        this.getPeople();
      },
      'filter.pageSize'() {
        this.getPeople();
      },
      'filter.sort'() {
        this.getPeople();
      }
    }
  };
</script>
<style lang="scss">
  #people-page {
    padding: 16px 0;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 4px 24px 0px rgba(58, 46, 38, 0.1);
    @media (max-width: 768px) {
      margin-top: 24px;
    }

    &:has(.no-items) {
      height: calc(100vh - 170px);
    }

    .no-items {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      margin: 0 16px;
      border: 1px solid #cfc4be;

      .empty-box {
        width: 80px;
        height: 80px;
      }

      .title {
        font-size: 16px;
        font-weight: 600;
        line-height: 26px;
        color: #4c4541;
        margin: 0;
      }

      .subtitle {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #998f8a;
        padding: 4px 0 12px;
        margin: 0;
      }

      .btn-new-person {
        border-color: #974900 !important;
        background-color: #974900 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        padding: 8px 21px;
        line-height: 20px;
        letter-spacing: 0.175px;

        .add-icon {
          width: 14px;
          height: 14px;
          fill: #fff;
        }
      }

      @media (max-width: 480px) {
        .empty-box {
          width: 40px;
          height: 40px;
        }

        .title {
          font-size: 14px;
        }

        .subtitle {
          font-size: 12px;
          text-align: center;
          padding: 4px 48px 12px;
          line-height: 16px;
        }

        .btn-new-person {
          padding: 4px 14px;
        }
      }
    }

    .header-block {
      padding: 0 16px;
      .title {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
      }
      .search-input {
        display: flex;
        align-items: center;
        padding: 16px 0 8px;

        @media (max-width: 580px) {
          flex-direction: column;
          gap: 8px;
        }

        .div-custom-input {
          display: flex;
          align-items: center;
          border-radius: 4px;
          width: 100%;
          overflow: hidden;
          border: 1px solid #cfc4be;
          .icon-block {
            display: flex;
            align-items: center;
            height: 14px;
            .search-icon {
              width: 14px;
              height: 14px;
              fill: #cfc4be;
            }
          }
          .input-search-people {
            width: 100% !important;
            border: none;
            overflow: hidden;

            &.focus {
              box-shadow: none;
            }
            &.b-form-tags {
              padding: 0 !important;
              display: flex;
              align-items: center !important;
              .b-form-tags-list {
                width: 100% !important;
                margin-left: 14px !important;
                margin-top: 0;
                height: 100% !important;
                flex-wrap: nowrap !important;
                display: flex !important;
                align-items: center !important;
                overflow: scroll;
                overflow-y: hidden;
                cursor: grabbing;
                cursor: -webkit-grabbing;

                .b-form-tags-field {
                  margin: 9px 0;
                }

                &::-webkit-scrollbar {
                  display: none !important;
                }
              }
            }

            .b-form-tags-button {
              display: none;
            }

            .b-form-tag {
              background-color: #974900;
              margin-right: 0.6rem;
              padding: 2px 8px !important;
              font-size: 13px;
            }
          }
        }

        .add-person {
          padding-left: 8px !important;
          padding-right: 0 !important;
          @media (max-width: 580px) {
            max-width: 100% !important;
            padding-left: 0 !important;
            flex: 0 0 100% !important;
            width: 100% !important;
          }
          .btn-new-person {
            border-color: #974900 !important;
            background-color: #974900 !important;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            width: 100% !important;
            padding: 8px 0 !important;
            line-height: 20px;
            letter-spacing: 0.175px;

            @media (max-width: 580px) {
              width: 100%;
              display: flex;
              justify-content: center;
              padding: 3.5px 0 !important;
            }

            .add-icon {
              width: 14px;
              height: 14px;
              fill: #fff;
            }
          }
        }
      }
    }
    .body-block {
      padding: 0 16px;
      margin: 0 14px;
      overflow-x: auto;
      &::-webkit-scrollbar {
        height: 3px;
      }

      &::-webkit-scrollbar-track {
        background: #fff;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #cfc4be;
        border-radius: 9px;
      }
    }
  }
</style>
